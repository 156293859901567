<template>
  <div class="notifyWizardStepZero mb-3">
    <div
      v-if="$parent.templatesAvailable == true && $parent.languageAvailable == true"
      v-show="$parent.checkActive(0)"
    >
      <!-- SEARCH FILTER -->
      <input
        v-model="$parent.filter"
        placeholder="search ..."
        class="mb-4 form-control"
      >
      <table class="defaultTable m-0">
        <thead>
          <!-- FOR LARGE SCREENS -->
          <tr class="d-none d-md-table-row">
            <th class="font-weight-bold">
              {{ $t('name') }}
            </th>
            <th class="font-weight-bold" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(template, index) in $parent.filterByTitle"
            :key="'tr1' + index"
          >
            <td v-if="template.name">
              {{ template.name }}
            </td>
            <td>
              <button
                class="btn btn-sm btn-primary"
                @click="$parent.setCurrent(template)"
              >
                {{ $t('choose') }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-else
      class="text-center"
    >
      <template v-if="!$parent.templatesAvailable">
        <span class="d-block mb-3">{{ $t('noDataAvailable') }}</span>
        <router-link
          :to="{ path: `/smsmanagement/template` }"
          class="btn btn-primary"
        >
          {{ $t('editTemplates') }}
        </router-link>
      </template>
      <template v-if="!$parent.languageAvailable">
        <p>{{ $t('noDataAvailable') }}</p>
        <a
          :href="$parent.jiraUrl( $parent.recipientId )"
          target="_blank"
          class="btn btn-primary"
        >{{ $t('editContacts') }}</a>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotifyWizardStepZero'
}
</script>
